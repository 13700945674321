import React from "react";

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  height?: number | string;
  width?: number | string;
}

const Svg: React.FC<SvgProps> = ({ height, width, ...props }) => {
  return (
    <svg
      height={height}
      width={width}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    />
  );
};

export default Svg;
